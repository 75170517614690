
import FaqItem from "./faq-item";
export default {
  name: "faq",
  components: {
    FaqItem,
  },
  data() {
    return {
      faqs: [
        {
          question: "How do I get a free report?",
          answer:
            "The FREE TRIAL runs a scan on selected services chosen by our tech team so you can see how it works in real time. To receive a full report requires the purchase of search credits for the online platforms or apps you want to scan.",
          open: false,
        },
        {
          question: "Why is the FREE TRIAL blurred?",
          answer:
            "The FREE TRIAL is a sample to illustrate how the service works. To see complete results for more than the sampled platforms requires purchase of at least one search credit.",
          open: false,
        },
        {
          question: "Why didn’t I receive a verification email?",
          answer:
            "Your verification email may have gone to your SPAM or BULK folder because they are generated automatically. Depending on your internet service provider or if you are trying to access iFindCheaters.com from a LAN terminal there may be filters that block automated email. We recommend you only access the site from your personal device. If your verification email does not appear within 10-15 minutes, please let us know.",
          open: false,
        },
        {
          question: "Why is the SCAN/SEARCH taking so long?",
          answer:
            "The SCAN/SEARCH times may vary depending on what time you submit your request. The average is 2-5 minutes, but it may take longer based on the search you request, and if the sites being scanned are receiving heavy user traffic.",
          open: false,
        },
        {
          question: "How do I see the SCAN/SEARCH results?",
          answer:
            "As soon as you have entered your payment information the results will become visible. For your convenience, you can also download the report as a printable PDF document.",
          open: false,
        },
        {
          question:
            "Do you provide my partner’s log information (username and password) for the sites or apps they have active accounts on?",
          answer:
            "Our service can confirm the existence of active accounts linked to your partner’s name and email address. We cannot access their passwords or materials they may have uploaded to those accounts. To do so would be an invasion of privacy and thus illegal.",
          open: false,
        },
        {
          question: "Is this free?",
          answer:
            "There is no cost to register or to browse the site. There is a FREE TRIAL available for either selected DATING/ALTERNATIVE LIFESTYLE SITES or selected SOCIAL MEDIA & MOBILE CHAT/HOOK-UP APPS. If you have requested a search using your partner’s name and email address, to access the complete results you will need to purchase one of the individual search credit options or a package plan. The technical maintenance and constant research and development required to keep our service current is a costly process, but we try to keep our prices as reasonable as possible. Every report is also downloadable as a PDF document.",
          open: false,
        },
        {
          question:
            "Can I cancel the FREE TRIAL at any time to avoid being billed?",
          answer:
            "No payment method is required to generate the FREE TRIAL scan. If you have purchased a Monthly option, you can request cancellation and will not be billed again at the end of the billing cycle.",
          open: false,
        },
        {
          question:
            "How do I learn if my partner is on DATING/ALTERNATIVE LIFESTYLE SITES or SOCIAL MEDIA & MOBILE CHAT/HOOK-UP APPS?",
          answer:
            "When you enter their full name (first and last) and an email address our algorithms will search the databases of the bundle of sites/platforms or apps you have chosen. If they have an active profile connected to the information you have entered it will be discovered. Once you know what services they are using, you can follow up to see if they are still on the internet dating market.",
          open: false,
        },
        {
          question:
            "DATING/ALTERNATIVE LIFESTYLE SITES or SOCIAL MEDIA & MOBILE CHAT/HOOK-UP APPS",
          answer:
          "<strong>FLEX</strong> – This option allows you to purchase as many search credits as you think you will need and use them individually to generate searches with any name or email address you choose.<br><br>  <strong>MONTHLY</strong> – This option runs a daily scan using one or more email addresses. You can request notification of results daily, or only when new activity is detected. Monthly plans have recurring billing. If you cancel the plan reports will continue to be generated until the end of that billing cycle but there will be no further charges.<br><br>  <strong>ULTIMATE</strong> – This option allows you to create the combination that works best for your needs. You can have some emails scanned daily and have individual credit bundles for single searches whenever you want them. Ultimate plans have recurring billing and any search credits not used the previous month carry over until used, they never expire.",
          open: false,
        },
      ],
    };
  },
  methods: {
    toggleOpen: function (index) {
      this.faqs = this.faqs.map((faq, i) => {
        if (index === i) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      });
    },
  },
};
